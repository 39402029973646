import clsx from 'clsx'
import { useEffect, useRef } from 'react'
import { useRequestData } from '../../request-data/pages-router/use-request-data'
import type { TestIdProp } from '../../../types'
import styles from './ad-slot.module.css'
import { useAditudeEnabled } from './use-aditude-enabled'

export type AdSlotConfig = {
  baseDivId: string
  native?: boolean
}

export type AdSlotProps = {
  className?: string
  config?: AdSlotConfig
  targeting?: Record<string, string | string[]> | null
} & TestIdProp

export function AdSlot({
  className,
  config,
  targeting,
  ...restProps
}: AdSlotProps) {
  const { off } = useRequestData()
  const ref = useRef<HTMLDivElement>(null)
  const baseDivId = config?.baseDivId
  const id = `aditude-ad-slot-${baseDivId}`
  const aditudeEnabled = useAditudeEnabled()

  useEffect(() => {
    if (ref.current && baseDivId) {
      window.tude = window.tude || {}
      window.tude?.cmd?.push(function renderAditudeSlot() {
        window.tude?.refreshAdsViaDivMappings?.([
          {
            divId: id,
            baseDivId,
            targeting,
          },
        ])
      })
    }
  }, [baseDivId, id, targeting])

  if (off.gpt || !config?.baseDivId || !aditudeEnabled) {
    return null
  }

  return (
    <div
      className={clsx(
        styles.slot,
        { [styles.native]: config?.native ?? false },
        className,
        'ad-slot'
      )}
      {...restProps}
      id={id}
      ref={ref}
      data-native-ad={config?.native ?? false}
    />
  )
}

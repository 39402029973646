import clsx from 'clsx'
import styles from './listing-card-skeleton.module.css'
import listingCardStyles from './listing-card.module.css'

export const ListingCardSkeleton = () => {
  return (
    <div
      className={clsx(listingCardStyles.card, styles.listingCardSkeleton)}
      data-tid="listing-card-skeleton"
    >
      <div className={styles.listingCardSkeletonInner}>
        <div className={styles.listingCardSkeletonCarousel} />

        <div className={styles.listingCardSkeletonContent}>
          <div className={styles.listingCardSkeletonBlocks}>
            <div className={styles.listingCardSkeletonBlock} />
            <div className={styles.listingCardSkeletonBlock} />
          </div>

          <div className={styles.listingCardSkeletonBlock} />
          <div className={styles.listingCardSkeletonBlock} />

          <div className={styles.listingCardSkeletonBlocks}>
            <div
              className={clsx(
                styles.listingCardSkeletonBlock,
                styles.listingCardSkeletonCta
              )}
            />
            <div
              className={clsx(
                styles.listingCardSkeletonBlock,
                styles.listingCardSkeletonCta
              )}
            />
          </div>
        </div>
      </div>
    </div>
  )
}
